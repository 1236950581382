<template>
  <b-sidebar
    id="add-new-patient-sidebar"
    :visible="isAddNewPatientSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-patient-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Patient
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onAddPatient)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group
              label="Full Name"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="patientData.fullName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Mobile -->
          <validation-provider
            #default="validationContext"
            name="Mobile"
            rules="required"
          >
            <b-form-group
              label="Mobile"
              label-for="mobile"
            >
              <b-form-input
                id="mobile"
                v-model="patientData.mobile"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Address -->
          <validation-provider
            #default="validationContext"
            name="Address"
            rules="required"
          >
            <b-form-group
              label="Address"
              label-for="address"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="address"
                v-model="patientData.address"
                :state="getValidationState(validationContext)"
                trim
                dir="auto"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- hearAboutClinicFrom -->
          <validation-provider
            #default="validationContext"
            name="HearAboutClinicFrom"
            rules="required"
          >
              <b-form-group label="HearAboutClinicFrom"
                            label-for="hearAboutClinicFrom"
                            :state="getValidationState(validationContext)">
                  <v-select v-model="patientData.hearAboutClinicFrom"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="hearAboutClinicFromOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                            input-id="hearAboutClinicFrom" />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'

import store from '@/store'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewPatientSidebarActive',
    event: 'update:is-add-new-patient-sidebar-active',
  },
  props: {
    isAddNewPatientSidebarActive: {
      type: Boolean,
      required: true,
    },
    hearAboutClinicFromOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    }
  },
  setup(props, { emit }) {
    // Use toast
    const toast = useToast()

    const blankPatientData = {
      fullName: '',
      address: '',
      mobile: '',
      hearAboutClinicFrom: '',
    }
    const patientData = ref(JSON.parse(JSON.stringify(blankPatientData)))
    const resetpatientData = () => {
      patientData.value = JSON.parse(JSON.stringify(blankPatientData))
    }

    const onAddPatient = () => {
      store.dispatch('patient-store/addPatient', patientData.value)
        .then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Operation Done',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'You have successfully add new patient!',
            },
          })
          emit('refetch-data')
          emit('update:is-add-new-patient-sidebar-active', false)
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Operation Failed',
              icon: 'XIcon',
              variant: 'danger',
              text: 'Error happened when adding new patient!',
            },
          })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetpatientData)

    return {
      patientData,
      onAddPatient,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-patient-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
